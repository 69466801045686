<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{
          Object.entries(this.data).length === 0 ? $t("AddNew") : $t("Update")
        }}
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <vs-card title="icon">
        <h4>* {{ $t("VehiclesWeight") }}</h4>
        <vs-input class="w-full" v-model="Weight" name="Weight" />

        <h4>* {{ $t("VehicleUnit") }}</h4>
        <v-select
          label="Name"
          class="mt-1 w-full"
          name="Name"
           v-validate="'required'"
          v-model="WeightUnitID"
          :reduce="(model) => model.ID"
          :options="WeightUnit"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />


      </vs-card>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">{{ $t("Submit") }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import moduleVehiclesCategory from "@/store/VehiclesSetting/VehiclesCategory/moduleVehiclesCategory";
 import moduleVehicleWeight from "@/store/VehiclesSetting/VehicleWeight/moduleVehicleWeight.js";

// import moduleVehicleLength from "@/store/VehiclesSetting/VehicleLength/moduleVehicleLength.js";
// import moduleVehicleWidth from "@/store/VehiclesSetting/VehicleWidth/moduleVehicleWidth.js";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          ID,
          Weight,
          UnitName,
          WeightUnitID,
          Name,

        } = this.data;

        this.Id = ID;
        this.Weight = Weight;
        this.UnitName = UnitName;
        this.WeightUnitID = WeightUnitID;
        this.Name = Name;

      }
    },
  },

  data() {
    return {
      ID: null,
      WeightUnitID: null,
      Weight: "",
      UnitName: "",
      Name:"",
      WeightUnit: [
        {ID: 1, Name: "كجم"},
        {ID: 2, Name: "طن"}

      ],


      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },

  computed: {

    VehicleWeights() {
      return this.$store.state.VehiclesWeightList.VehicleWeights;
    },
    // GetWeightUnits() {
    //   return this.$store.state.VehiclesWeightList.VehiclesWeights;
    // },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.Id;
    },
  },

  methods: {
    initValues() {
      this.Id = null;
      this.Weight = "";
      this.UnitName = "";
      this.WeightUnitID = null;
      this.Name= "";
    },


    submitData() {
      debugger;


      this.$validator.validateAll().then((result) => {
        console.log("result",result);
        if (result) {
          const obj = {
            ID: this.Id,
            Weight:this.Weight,
            WeightUnitID:this.WeightUnitID,
            UnitName:this.UnitName,
            Name:this.Name
          };

          if (this.Id !== null && this.Id > 0) {
            this.$store
              .dispatch("VehiclesWeightList/updateItem", obj)
              .then(() => {
                this.$store
                  .dispatch("VehiclesWeightList/fetchAllVehiclesWeightList")
                  .then(() => {
                    this.$vs.loading.close();
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          // if (this.ID !== null && this.ID >= 0) {
          //   debugger;
          //   this.$store
          //     .dispatch("VehiclesWeightList/updateItem", obj)
          //     .then(() => {
          //       this.$store
          //         .dispatch("VehiclesWeightList/fetchAllVehiclesWeightList")
          //         .then(() => {
          //           this.$vs.loading.close();
          //         });
          //       window.showSuccess();
          //     })
          //     .catch((err) => {
          //       console.error(err);
          //     });
        } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store
              .dispatch("VehiclesWeightList/addItem", obj)
              .then(() => {
                this.$store
                  .dispatch("VehiclesWeightList/fetchAllVehiclesWeightList")
                  .then(() => {
                    this.$vs.loading.close();
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }
          // } else {
          //   delete obj.ID;
          //   obj.popularity = 0;
          //   this.$store
          //     .dispatch("VehiclesWeightList/addItem", obj)
          //     .then(() => {
          //       this.$store
          //         .dispatch("VehiclesWeightList/fetchAllVehiclesWeightList")
          //         .then(() => {
          //           this.$vs.loading.close();
          //         });
          //       window.showSuccess();
          //     })
          //     .catch((err) => {
          //       console.error(err);
          //     });
          // }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },

  created() {
    this.$vs.loading();
    debugger;

    if (!moduleVehicleWeight.isRegistered) {
      this.$store.registerModule("VehiclesWeightList", moduleVehicleWeight);
      moduleVehicleWeight.isRegistered = true;
    }
    this.$store.dispatch("VehiclesWeightList/fetchAllVehiclesWeightList");
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
