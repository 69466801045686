/*=========================================================================================
  File Name: modulecourseClassMutations.js
  Description: courseClass Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_VehicleWeight(state, item) {
    state.VehicleWeights.unshift(item);
  },

  SET_VehicleWeight(state, VehicleWeight) {
    debugger;
    state.VehicleWeights = VehicleWeight;
  },

  UPDATE_VehicleWeight(state, item) {
    const Index = state.VehicleWeights.findIndex(p => p.ID == item.ID);
    Object.assign(state.VehicleWeights[Index], item);
  },

  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.VehicleWeights.findIndex(p => p.ID == itemId);
    state.VehicleWeights.splice(ItemIndex, 1);
  }

};
